import { parseNumber } from 'libphonenumber-js'

import i18nMessages from '../plugins/i18n/messages'
import i18nDateTimeFormats from '../plugins/i18n/dateTimeFormats'
import i18nNumberFormats from '../plugins/i18n/numberFormats'
import { Config } from '@/services/ConfigService'

export default {
	data: function () {
		return {
			rules: {
				date: date => {
					if (this.validateDate(date)) {
						return true
					}
					return this.$t('rules.date')
				},
				email: email => {
					if (this.validateEmail(email)) {
						return true
					}
					return this.$t('rules.email')
				},
				noSpecialChar: value => {
					if (this.validateNoSpecialChar(value)) {
						return true
					}
					return this.$t('rules.no_special_char')
				},
				required: value => {
					if (this.validateRequired(value)) {
						return true
					}
					return this.$t('rules.required')
				},
				siren: siren => {
					if (this.validateSiren(siren)) {
						return true
					}
					return this.$t('rules.siren')
				},
				siret: siret => {
					if (this.validateSiret(siret)) {
						return true
					}
					return this.$t('rules.siret')
				},
				url: url => {
					if (this.validateUrl(url)) {
						return true
					}
					return this.$t('rules.url')
				},
				domain: url => {
					if (this.validateDomain(url)) {
						return true;
					}
					return this.$t('rules.domain')
				}
			}
		}
	},
	i18n: {
		messages: i18nMessages,
		dateTimeFormats: i18nDateTimeFormats,
		numberFormats: i18nNumberFormats
	},
	methods: {
		validateDate: function (date) {
			if (!date) {
				return true
			}
			if (typeof date !== 'string') {
				return false
			}
			if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
				const [day, month, year] = date.split('/')
				date = year + '-' + month + '-' + day
			}
			const dateObject = new Date(date)
			return dateObject.toString() !== 'Invalid Date'
		},
		validateEmail: function (email) {
			if (!email || email.trim().length === 0) {
				return true
			}
			if (email && typeof email !== 'string') {
				return false
			}
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(email)
		},
		validateNoSpecialChar: function (value) {
			return value == null ? true : !/[/|:?<>*"]/.test(value)
		},
		validatePhoneNumber: function (value, country = 'FR') {
			if (!value || value.trim().length == 0) {
				return true
			}
			if (value && typeof value != 'string') {
				return false
			}

			return Object.keys(parseNumber(value, country)).length > 0
		},
		validateRegex: function (regex, value) {
			if (!value) {
				return true
			}

			const regExp = new RegExp(regex)

			return regExp.test(value)
		},
		validateRequired: function (value) {
			if (!value) {
				return false
			}
			return !(typeof value === 'string' && value.trim().length === 0)
		},
		validateSiren: function (siren) {
			if (!siren) {
				return true
			}
			siren = '' + siren + ''
			if (siren.trim().length === 0) {
				return false
			}
			const pattern = /^\d{9}$/
			return pattern.test(siren)
		},
		validateSiret: function (siret) {
			if (!siret || siret.trim().length === 0) {
				return true
			}
			siret = '' + siret + ''
			const pattern = /^\d{14}$/
			if (siret && !pattern.test(siret)) {
				return false
			}
			let sum = 0
			for (let i = 0; i < 14; i++) {
				const rank = 13 - i
				let num = 0
				if (i % 2) {
					num = parseInt(siret.charAt(rank)) * 2
					if (num > 9) {
						num = num - 9
					}
				} else {
					num = parseInt(siret.charAt(rank))
				}
				sum = sum + num
			}
			return sum % 10 === 0
		},
		validateUrl: function (url) {
			if (!url) {
				return true
			}

			let _url

			try {
				_url = new URL(url)
			} catch (_) {
				return false
			}

			return (_url.protocol === 'http:' || _url.protocol === 'https:')
				&& (url.match(/:\/\//g) || []).length == 1
		},
		validateDomain: function (url) {
			if (!url) {
				return true
			}

			const regex = /^(?!:\/\/)([a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,}$/;
			const baseDomain = Config.VUE_APP_BASE_DOMAIN
			const subDomainRegex = url.includes(baseDomain) ? new RegExp("^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\\." + baseDomain + "$") : /^.*$/;

			return regex.test(url) && subDomainRegex.test(url);
		}
	}
}
