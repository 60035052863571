<template>
	<w-text-input
		v-model="copiedValue"
		inputmode="email"
		:label="label"
		prepend-icon="email"
		:required="required"
		:rules="inputRules"
		type="email"
		:validate-on-blur="validateOnBlur"
		v-bind="$attrs"
		v-on="$listeners"
	/>
</template>

<script>
import Validator from '@/mixins/Validator'

/**
 * @displayName w-email-input
 *
 * @since 0.3.2
 */
export default {
	name: 'WEmailInput',
	mixins: [Validator],
	props: {
		label: {
			required: false,
			type: String,
			default: ''
		},
		required: {
			required: false,
			type: Boolean,
			default: true
		},
		validateOnBlur: {
			default: true,
			required: false,
			type: Boolean
		},
		value: {
			required: false,
			type: String,
			default: ''
		}
	},
	computed: {
		copiedValue: {
			get: function () {
				return this.value
			},
			set: function (val) {
				this.$emit('input', val)
			}
		},
		inputRules: function () {
			let result = []
			if (this.$attrs.hasOwnProperty('rules') && Array.isArray(this.$attrs.rules)) {
				result = this.$attrs.rules
			}
			return result
		},
	}
}
</script>
